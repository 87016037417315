/* portal.scss

  This is the manifest for the parent portal.

*/

// Bootstrap
@import '../shared/helpers/bootstrap_loader';

// Load SA variables
@import '../shared/helpers/variables';

// Load base styles
@import '../shared/base/base';
@import './base/base';
@import './base/react_parent_portal';

// Load layout styles
@import '../shared/layout/body';
@import '../shared/layout/footer';
@import '../shared/layout/header';
@import '../shared/layout/line_breaks';
@import '../shared/layout/teacher_rec';

// Load modules
@import '../shared/modules/browser_incompatible';
@import '../shared/modules/buttons';
@import '../shared/modules/crop_dialog';
@import '../shared/modules/header_action';
@import '../shared/modules/maintenance_warning';
@import '../shared/modules/modal';
@import '../shared/modules/notification';
@import '../shared/modules/popover';
@import '../shared/modules/portal_nav';
@import '../shared/modules/radio';
@import '../shared/modules/required_label';
@import '../shared/modules/section';
@import '../shared/modules/session_timeout_warning';
@import '../shared/modules/sub_field_label';
@import '../shared/modules/weglot';

@import './modules/authentication';
@import './modules/calendar';
@import './modules/checklist';
@import './modules/create_account';
@import './modules/cta_widget';
@import './modules/file_upload';
@import './modules/forgot_password';
@import './modules/form_responsive';
@import './modules/help';
@import './modules/open_enrollment';
@import './modules/page_header';
@import './modules/sign_in';
@import './modules/student_summary';
@import './modules/summary';

// Load third-party npm assets
@import '~fullcalendar/dist/fullcalendar.css';
@import '~blueimp-file-upload/css/jquery.fileupload-ui.css';
@import '~blueimp-file-upload/css/jquery.fileupload.css';
@import '~jquery-jcrop/css/jquery.Jcrop.css';
