/* _required_label.scss

  Required labels for fields. This adds '*' to all labels have the class.

*/

.label-required:after, .label-required-prepend:before {
  content: '*';
  color: $red2;
}

.label-required-prepend:after {
  content: '';
}