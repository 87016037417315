/* _open_enrollment.scss

  Styles used for open enrollment in the parent portal. We use the 'oe' prefix to keep class names concise.

*/

@import '../../shared/helpers/bootstrap_variables';

.oe-instructions {
  .notification-icon > i {
    line-height: 28px;
  }

  .notification-text {
    font-size: 16px;
    font-weight: bold;
    color: $gray0;
  }
}

.oe-add-student-address {
  .sa-form-required-label {
    @extend .label-required;
  }
}

.oe-add-student-header {
  font-weight: bold;
  display: inline-block;
  margin: 0 10px 0 0;
}

.oe-add-student-control {
  margin: 15px 0 0 0;
}

.oe-add-student input[type='text'] {
  @extend .form-control;
  @extend .form-control-sm;
}

.oe-add-student {
  @extend .card;
  @include box-shadow(none);

  padding: 10px;
  margin: 5px 0 0 0;
  border-style: dotted;
  border-width: 2px;
}

.oe-add-student-field {
  @include make-row();

  margin-top: 20px;
}

.oe-new-household .sa-form-sub-field {
  margin-top: 10px;
}

.oe-add-student-label {
  @include make-col-ready();
  @include make-col(8);

  @include media-breakpoint-down(sm) {
    flex: 1 1 auto;
    width: 100%;
    text-align: left;
  }

  text-align: right;
  line-height: 32px;
}

.oe-add-student-label, .oe-sub-field-label-required {
  @extend .label-required;
}

.oe-add-student-body {
  @include make-col-ready();
  @include make-col(16);
  @include media-breakpoint-down(sm) {
    flex: 1 1 auto;
    width: 100%;
  }

  @include make-row();
}

.oe-add-student-name {
  @include make-col-ready();
  @include make-col(24);

  @include media-breakpoint-up(sm) {
    @include make-col(8);
  }

}

.oe-add-student-grade {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-down(sm) {
    flex: 1 1 auto;
    width: 100%;
  }

  select {
    @extend .form-select;
    @extend .form-select-sm;
  }
}

.oe-add-student-radios {
  @include make-col-ready();
  @include make-col(24);

  margin-top: 7px;
}

.oe-new-guardian {
  @include make-col-ready();
  @include make-col(24);
}

.oe-add-student-address {
  @include make-col-ready();
  @include make-col(24);
}

.oe-sub-field-label {
  @extend .form-text;
}

.oe-add-student-email {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-down(sm) {
    flex: 1 1 auto;
    width: 100%;
  }
}

.oe-add-student-phone-type {
  @include make-col-ready();
  @include make-col(8);
}

.oe-add-student-phone-number {
  @include make-col-ready();
  @include make-col(16);
}

.oe-add-guardian-row {
  @include make-row();

  margin-top: 5px;
}

.oe-new-household {
  display: none;
}

.oe-new-guardian {
  display: none;
  margin: 15px 0 0 0;
}

.oe-add-another-button, .oe-done-adding-button, .oe-cancel-button {
  margin: 0 5px 0 0;
}

.oe-add-another-button {
  @extend .btn;
  @extend .btn-success;

  margin-bottom: 5px;

  @include media-breakpoint-up(md) {
    display: inline-block;
    margin-bottom: 0;
  }
}

.oe-done-adding-button {
  @extend .btn;
  @extend .btn-primary;
}

.oe-cancel-button {
  @extend .btn;
  @extend .btn-danger-inline;

  float: right;
}

.oe-other-students-message {
  font-size: 18px;
}
