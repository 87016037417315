/* _header_actions.scss

  Header actions widget for the parent portal. On mobile, this changes the menu from a vertical menu to a collapsible
  vertical menu.

*/

.header-actions {
  list-style: none;
  float: right;
  padding-left: 0px;
  margin-bottom: 0;

  @include media-breakpoint-down(md) {
    float: none;
  }
}

.header-actions > li {
 @include media-breakpoint-up(lg) {
    text-align: center;
    float: left;
    padding: 0 12px 6px 12px;

    &:first-of-type {
      padding-left: 0px;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  @include media-breakpoint-down(md) {
    float: none;
    text-align: center;
    padding: 10px 0;

    a {
      color: $white;
    }

    .header-action-label {
      font-size: 16px;
    }


    &:not(:last-of-type) {
      border-bottom: 1px solid $gray2;
    }
  }
}

.header-actions a {
  color: $gray0;
  font-weight: bold;
  display: block; // Make the entire rectangle clickable, including whitespace

  &:hover {
    text-decoration: none;
  }
}

.header-actions li.active a{
  color: $teal2;
}

.header-actions li.active {
  border-bottom: 3px solid $teal2;
}


.header-actions-left {
  @include media-breakpoint-up(lg) {
    float: left;
  }

  > li {
    border-bottom: 1px solid $gray2;

    @include media-breakpoint-up(lg) {
      border-bottom: none;
    }
  }

  .header-action-label {
    @include media-breakpoint-up(lg) {
      vertical-align: top;
      font-size: 18px;
    }
  }

  .header-action-icon {
    font-size: 20px;

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
  }
}

.header-actions-right {
  .header-action-label {
    @include media-breakpoint-up(lg) {
      vertical-align: top;
      font-size: 12px;
    }
  }

  .header-action-icon {
    font-size: 16px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }
}

.header-action-icon {
  font-size: 16px;

  @include media-breakpoint-down(md) {
    font-size: 14px;
  }
}

.header-action-label {
  display: inline-block;
}