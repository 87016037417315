/* _file_upload.scss

  File upload widget for the parent portal checklist

*/

.file-upload-max-size {
  display: inline-block;
  font-size: 11px;
  color: $gray1;
  vertical-align: bottom;
  margin: 0 0 0 5px;
}

.file-upload-progress {
  @extend .progress;
  margin-top: 1px;
  margin-bottom: 0;
  width: 100%;
  display: none;

  @include media-breakpoint-up(md) {
    width: 200px;
  }
}

.file-upload-progress-bar {
  @extend .progress-bar;
  @extend .bg-success;
}

.file-upload-divider:before {
  content: '|';
  display: none;
  padding: 0 10px;
  color: $gray0;

  @include media-breakpoint-down(md) {
    display: inline-block;
  }
}

.file-upload-delete-link {
  color: $red1;
  display: none;

  @include media-breakpoint-down(md) {
    display: inline-block;
  }

  a {
    color: $red1;

    &:hover {
      color: $red0;
    }
  }
}

// Show the delete link and divider when the "download" link is hovered
.checklist-item:hover .file-upload-delete-link, .checklist-item:hover .file-upload-divider:before {
  display: inline-block;
}

.file-upload-widget-uploading {
  .fileinput-button {
    display: none;
  }
  .max-size {
    display: none;
  }
  .file-upload-progress {
    display: block;
  }
}

