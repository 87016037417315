/* base.scss

  Base styles that are shared between the admin portal, parent portal, and forms.

  A Base rule is applied to an element using an element selector, a descendant selector, or a child selector, along with
  any pseudo-classes. It doesn’t include any class or ID selectors. It is defining the default styling for how that
  element should look in all occurrences on the page.
*/

body {
  -webkit-font-smoothing: antialiased;
}

// override for bootstrap 2 -> 3 default box-sizing change
.reset-box-sizing,
.reset-box-sizing *,
.reset-box-sizing *:before,
.reset-box-sizing *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  resize: both; // overrides bootstrap
}

.form-control {
  // Make readonly controls look like disabled controls in bootstrap. (rolls back fix: https://github.com/twbs/bootstrap/pull/36499)
  &[readonly], &[readonly]:focus {
    color: $input-disabled-color !important;
    background-color: $input-disabled-bg !important;
    border-color: $input-disabled-border-color !important;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1 !important;
    outline: none !important;
  }

}
