/* _checklist.scss

  Parent portal checklist styles. Note: some checklist item types have their own stylesheets. Please verify that this is
  not the case for the item you are working on before adding anything to this file.

*/

.checklist {
  @include make-col-ready();
  @include make-col(24);

  @include media-breakpoint-up(lg) {
    @include make-col(14);
  }

  clear: both;
}

.checklist-upload-delete-link {
  text-decoration: none;
}

.checklist-upload-max-size {
  margin-top: 4px;
  font-size: 12px;
}

.checklist-upload-progress {
  display: none;
}

.checklist-items {
  list-style: none outside none;
  padding-left: 0;
}

.checklist-item {
  padding: 15px 0px 5px;
  border-bottom: 1px solid $gray7;
  color: $gray0;
}

.checklist-sub-item {
  margin-left: 30px;
}

.checklist-item-status, .checklist-item-box {
  float: left;
  width: 30px;

  > i {
    font-size: 20px;
    color: $green2;
  }
}

.checklist-item-details {
  margin-left: 30px;
}

.checklist-item-name {
  font-size: 18px;
  line-height: 18px;
  vertical-align: text-top;
  margin: 2px 10px 10px 0;
  float: left;
}

.checklist-item-action {
  width: 100%;
  display: block;
  clear: both;
  margin: 0 0 10px 0;

  .btn {
    font-size: 13px;
  }

  @include media-breakpoint-up(md) {
    width: inherit;
    float: left;
    clear: none;
    margin-bottom: 0;
  }
}

.checklist-item-row {
  @include clearfix();
  clear: both;
}

.checklist-item-hint {
  float: left;
}

.checklist-item-link {
  a {
    display: inline-block;
  }

  color: $blue1;
  float: right;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.checklist-item-explanation {
  margin: 0 0 8px 0;
}

.checklist-item-complete {
  .checklist-item-name, .checklist-item-hint, .checklist-item-explanation {
    color: $gray2;
  }
}

.checklist-portrait-rotate-button {
  margin: 0 2px 8px 2px;
}

.checklist-item-appointment-date {
  font-weight: bold;
}

.checklist-item-appointment {
  height: 50px;
}

.checklist-item-appointment-link {
  height: 50px;
}

.checklist-item-appointment-cancel {
  color: $red1;
  display: inline;
  margin: 0 0 0 5px;
  a {
    color: $red1;
  }

  @include media-breakpoint-down(sm) {
    display: block;
    margin: 0;
  }
}

.checklist-item-appointment-reschedule {
  color: $blue1;
  display: inline;
  margin: 0 0 0 5px;

  @include media-breakpoint-down(sm) {
    display: block;
    margin: 0;
  }
}

.manage-uploaded-files {
  .file-name{
    display: inline;
    width: 60%;
  }

  .action {

    a {
      text-decoration: none;
    }
  }

  .text-end{
    width: 40%;
    float: right;
    display: inline;
  }

  .delete-file-link{
    color: $red1;
    display: none;
  }

  .file-details:hover{
    .delete-file-link{
      display: inline;
    }
  }

  .red{
    color: $red1;
  }

  .green{
    color: $green1;
  }

  .upload{
    margin-top: 10px;
  }
}

.btn-pending-signatures {
  background-color: $yellow0;
  border-color: $yellow0 !important;
  color: $white !important;

  &:hover {
    background-color: $yellow0 !important;
    border-color: $yellow0 !important;
  }
}
