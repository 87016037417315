/* _calendar.scss

  Calendar styles for public and parent calendars.

*/

.parent-calendar {
  padding: 0 20px;

  @include media-breakpoint-down(sm) {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.calendar-title, .calendar-description {
  margin: 0 0 15px 0;
}

.calendar {
  @include media-breakpoint-down(sm) {
    h2 {
      font-size: 14px;
    }

    .fc-button {
      padding-left: .1em;
      padding-right: .1em;
    }
  }
  
  .fc-event {
    cursor: pointer;
  }
}

.appointment {
  iframe {
    border: none;
    overflow: hidden;
    width: 100%;
    display: block;
  }

  .appointment-spinner {
    display: block;
    margin: 10px auto;
  }
}
