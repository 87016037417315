/* _help.scss

  Help widget for the parent portal dashboard. This is the widget that lists contact numbers and e-mail addresses for
  several individuals at the school.

*/

.help {
  @include make-col-ready();
  @include make-col(24);

  @include media-breakpoint-up(lg) {
      @include make-col(10);
    }


  margin-bottom: 10px;

  img {
    max-width: 100%;
  }
}

.help-contact-title {
  font-weight: bold;
}

.help-contact {
  margin-bottom: 18px;
}
