/* _student_summary.scss

  Lists a summary for each student on the parent dashboard.

*/

.student-summaries {
  @include make-col-ready();
  @include make-col(24);

  @include media-breakpoint-up(lg) {
    @include make-col(14);
  }

  margin-bottom: 10px;
}

.student-summary {
  @include make-row();
}

.student-summary-progress-bar-container {
  margin-bottom: 8px;
  max-width: 300px;

  .progress {
    margin-bottom: 0px;
  }
}

.student-summary-next-action {
  @include make-col-ready();
  @include make-col(24);

  // Make .next-action and .other-actions go to the same line and switch positions on md screens
  @include media-breakpoint-up(lg) {
    @include make-col(13);
  }

  margin-bottom: 20px;

  .explanation-text {
    margin-top: 6px;
  }
}

.student-summary-other-actions {
  @include make-col-ready();
  @include make-col(24);

  // Make .next-action and .other-actions go to the same line and switch positions on md screens
  @include media-breakpoint-up(lg) {
    @include make-col(11);
    text-align: end;
  }

  margin-bottom: 15px;
}

.student-summary-name {
  @include make-col-ready();
  @include make-col(24);
  font-size: 18px;
  font-weight: bold;
  color: $gray0;
}
.student-summary-track {
  @include make-col-ready();
  @include make-col(24);

  @include media-breakpoint-up(lg) {
    @include make-col(14);
  }

  font-size: 13px;
  color: $gray1;
  margin-bottom: 8px;
}
.student-summary-progress {
  @include make-col-ready();
  @include make-col(24);

  @include media-breakpoint-up(lg) {
    @include make-col(10);
  }
}

.student-summary-divider-container {
  margin-bottom: 10px;
}

.student-summary-divider {
  border-bottom: 1px solid $gray7;
}

.student-summaries-instructions {
  color: $gray1;
}

.student-summary-action-link {
  display: inline-block;
}

