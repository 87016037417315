/* _cta_widget.scss

  Call to action widgets used on the parent dashboard that encourage parents to enter an authentication code or to fill
  out an application.

*/

@import '../../shared/helpers/bootstrap_variables';

.cta-widgets {
  @include make-row();
}

.cta-widget-container {
  @include make-col-ready();

  @include media-breakpoint-up(xl) {
    @include make-col(12);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(24);
  }

  @include media-breakpoint-up(md) {
    @include make-col(12);
  }

  margin-top: 20px;
}

.cta-widget-container-full {
  @include make-col-ready();
  @include make-col(24);
}

.cta-empty {
  @include make-col-ready();
  @include media-breakpoint-up(lg) {
    @include make-col(24);
  }
}

/* COMPONENT - CTA WIDGET */
.cta-widget {
  border-radius: 6px;
  background-color: $gray5;
  border: 1px solid $gray7;
}

.cta-widget-large {
  padding: 10px 5px;
  max-width: 350px; // It doesn't look good when stretched too wide
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
}

.cta-widget-small {
  padding: 10px;
  margin-top: 10px;
}

.cta-widget-green {
  &.cta-widget-large {
    border-color: lighten($green1, 40);

    .fa-arrow-down {
      color: $green1;
    }
  }

  &.cta-widget-small {
    a {
      color: $green6;
    }
  }
}

.cta-widget-blue {
  &.cta-widget-large {
    border-color: lighten($teal2, 60);

    .fa-arrow-down {
      color: $teal2;
    }
  }

  &.cta-widget-small {
    a {
      color: $teal2;
    }
  }
}

.cta-green-button {
  background-color: $green1;
}

.cta-blue-button {
  background-color: $blue1;
}
