@media print {
  .page-break {
    display: block;
    page-break-after: always;
    clear: both;
  }

  .page-break:last-child {
    page-break-after: auto;
    clear: both;
  }

  .break-after {
    page-break-after: always;
    clear: both;
  }

  .break-before {
    page-break-before: always;
    clear: both;
  }
}

