/* _buttons.scss

  Sets overrides for bootstrap's buttons

*/

@import '../helpers/bootstrap_variables';

.btn {
  &[disabled],
  &:disabled {
    pointer-events: auto;
    cursor: not-allowed;
  }
}

.btn-danger-inline, .btn-danger-inline:hover {
  @extend .btn-link;

  color: $red1;
}

.btn-xs {
  padding: 0 5px;
  font-size: 12px;
  line-height: 17px;
}

.btn-link:hover {
  text-decoration: underline;
}

@media print {
  .btn {
    display: none;
  }
}
