.crop-dialog {
  @extend .card;
  @extend .card-body;
  @extend .bg-light;
  text-align: left;
  position: relative;
  display: none;
  img {
    // This rule is required for Jcrop to work in a Bootstrap modal!
    max-width: none;
  }
  .left-pane {
    display: inline-block;
    margin: 0;
    text-align: center;
    .original {
      display: block;
      max-width: 360px;
      max-height: 300px;
    }
    .jcrop-holder {
      margin: 0 auto 5px auto;
    }
  }
  .right-pane {
    @include media-breakpoint-down(sm) {
      // Hide crop preview in XS screen mode
      display: none;
    }
    margin: -75px 0 0 40px;
    display: inline-block;
    position: absolute;
    top: 50%;
    text-align: center;
    .preview {
      margin: auto auto 5px auto;
      overflow: hidden;
      width: 100px;
      height: 100px;
    }
  }
  .button-row {
    margin: 19px 0 0 0;
  }
  .file-upload-widget, .file-upload-button, .file-upload-progress, .file-upload-progress-bar {
    display: inline-block;
  }
  .file-upload-widget {
    vertical-align: top;
    &.file-upload-widget-uploading .file-upload-progress {
      display: inline-block !important;
    }
    .file-upload-progress {
      padding: 0;
    }
  }
}
