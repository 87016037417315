.sa-form-teacher-rec {
  @include make-col(24);
}

.sa-form-teacher-rec-buttons{
  float: right;

  margin-top: 8px;
}

.sa-form-teacher-rec-help {
  @extend .btn;
  @extend .btn-link;
}

.sa-form-teacher-rec-decline-button {
  @extend .btn;
  @extend .btn-danger;
}

// We have to increase specificity here in order to beat ".form-horizontal .col-form-label"
.sa-form-teacher-rec-decline .sa-form-teacher-rec-decline-label {
  @extend .col-form-label;
  @include media-breakpoint-up(lg) {
    @include make-col(5);
    text-align: left;
  }
}

.sa-form-teacher-rec-decline-input {
  @include media-breakpoint-up(lg) {
    @include make-col(12);
  }
}



.teacher-rec-modal-label {
  @extend .col-form-label;

  @include make-col(8);

  text-align: end;
}

.teacher-rec-modal-input {
  @include make-col();
}

.teacher-rec-modal-input-half {
  @include make-col(8);
  
  text-align: end;
}

.teacher-rec-modal-input-static {
  @extend .form-control-plaintext;
}

.teacher-rec-modal-required-label {
  @extend .label-required;
}

// TODO: Cleanup

.sa-form-teacher-rec {
  h1 {
    font-size: 18px;
    color: $gray71;
  }

  h2 {
    font-size: 18px;
    font-weight: normal;
    color: $red18;
    margin-bottom: 0;
  }

  h3 {
    font-size: 13px;
    font-weight: bold;
    color: $gray48;
  }

  .recommend-points {
    margin-left: 20px;

    h2 {
      font-size: 14pt;
      font-weight: normal;
      color: $blue16;
    }
  }

  .recommend-outside {
    width: 970px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
  }

  .recommend-outside .nav .decline {
    margin-top: 8px;
  }

  // make the help pop-up wider
  .popover {
    width: 325px;
  }

}