/* _modal.scss

  These are styles for modals shared between the parent and admin portals.

*/

.modal {

  .col-form-label { 
    font-weight: bold;
    text-align: end;
  }

}