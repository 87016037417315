/* _summary.scss

  A summary widget used on the parent dashboard where the customer can display custom text.

*/

.summary-container {
  @include make-row();
}

.summary {
  @include make-col-ready();
  @include make-col(24);

  @include media-breakpoint-up(lg) {
    @include make-col(14);
  }

  // Make sure the portal widget gets its own row when the welcome message wraps on mobile screens
  margin-bottom: 10px;
}

.summary-title {
  margin-top: 0;
  display: inline-block;
}

.summary-student-name {
  margin: 0 0 2px 0;
  font-size: 24px;
}

.summary-student-status {
  font-size: 18px;
  color: $gray1;
  font-weight: 300;
}

.summary-communication {
  margin-bottom: 0;

  h1, h3 {
    margin-top: -3px;
  }

  h2, h4, h5, h6 {
    margin-top: 0;
  }
}
