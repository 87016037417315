/* _notification.scss

  These are a modified version of bootstrap's notifications. The color styles for this will almost always be overridden
  with customer colors.

*/

.notification-alert {
  border-radius: 5px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 10px;
  // Using this approach we'll have to convert from hex to decimal when generating the customer CSS
  background-color: rgba(234, 104, 0, 0.1);
}

.notification-icon {
  text-align: center;

  > i {
    font-size: 30px;
    float: left;
    width: 40px;
    color: $red17;
  }
}

.notification-text {
  color: $gray48;
  margin-left: 10px;
  padding-top: 4px;
}