/* _portal_nav.scss

  Collapsible portal navigation menu.

*/

.portal-nav {
  @include media-breakpoint-down(md) {
    border: none;
    margin: 0;

    .navbar-collapse {
      background-color: $white;
    }

  }

  .navbar-toggler{
    margin: 5px 10px 10px 0;

    &:focus {
      box-shadow: none;
    }
  }

  overflow: hidden;
  margin-bottom: 15px;
  border-bottom: 1px solid $gray2;
}

.portal-sub-nav{
  overflow: hidden;
  padding: 0 15px 15px 0;
}

.portal-nav-right {
  float: right;

  @include media-breakpoint-down(md) {
    float: none;
  }
}
